import React from 'react'
import HeaderPaginas from '../../../../components/headerPaginas'
import Layout from '../../../../components/layout'
import Seo from '../../../../components/SEO/SEO'
import CtaServicios from '../../../../components/ctaServicios'

export default function Integracion(props) {
    return (
        <Layout>
            <Seo pathname={props.location.pathname} />
            <div className="cabecera-servicio">
                <HeaderPaginas titulo="Análisis de vulnerabilidades web" imagen="/img/cabeceras/analisis-vulnerabilidades-web.jpg" />
                <div className="intro-servicio">
                    <p>Aplicaciones web o móvil: las usamos durante 24 horas, los 365 días del año.., entonces si son "casita", ¿por qué dejas su seguridad en el limbo?</p>
                </div>
            </div>
            <div className="cuerpo-servicio">
                <section className="">
                    <div className="limitador">
                        <div className="fila-cards-cpd-propio-caracteristicas">
                            <div className="card-cpd-propio-caracteristicas">
                                <div className="col-imagen" style={{ backgroundImage: "url(/img/analisis-vulnerabilidades-1.jpg)" }}>
                                </div>
                                <div className="col-texto">
                                    <div>
                                        <h3>Las aplicaciones web y móviles constituyen una de las partes más sensibles de ser atacadas, ¿por qué?</h3>
                                        <ul>
                                            <li>Se encuentran en un entorno público como es internet: a la vista de todos.</li>
                                            <li>Gran crecimiento y difusión: cada vez hay más.</li>
                                            <li>Gestionan y mueven datos: datos privados y relevantes, financieros, de salud, en definitiva, datos muy sensibles. Y los datos son el objetivo más buscado por su gran valor hoy día.</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <h3>Objetivos de nuestro análisis de vulnerabilidades web</h3>
                                        <ul>
                                            <li>Certificar el grado de seguridad existente en la aplicación a testear y los sistemas relacionados con la misma.</li>
                                            <li>Valorar si se cumplen las políticas, normas, prácticas y procedimientos relativos a la Seguridad en las TIC.</li>
                                            <li>Detección de carencias que pudieran existir.</li>
                                            <li>Propuesta de medidas correctoras.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="fila-cards-cpd-propio-caracteristicas">
                            <div className="card-cpd-propio-caracteristicas">
                                <div className="col-texto">
                                    <div>
                                        <h3>¿Y después? ¿Cómo procedemos?</h3>
                                        <p>La forma de proceder de nuestro equipo siempre va a depender del tipo de vulnerabilidad detectada:</p>
                                        <ul>
                                            <li><strong>Vulnerabilidad grave:</strong> Si se localiza una vulnerabilidad grave se indica en el mismo momento de la detección: el tiempo es oro.</li>
                                            <li><strong>Vulnerabilidad media o leve:</strong> Si se localiza una vulnerabilidad media o leve se indicará en el informe detallado de la misma.</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <h3>¿Y quién lo corrige en la web o app?</h3>
                                        <p>Generalmente el equipo de desarrollo que ha construído el proyecto o aplicación, pero si quieres contar con nuestra ayuda también contamos con un equipo experto en desarrollo en <a href="https://bullhost.agency" target="_blank" rel="noreferrer noopener">bullhost.agency</a></p>
                                    </div>
                                </div>
                                <div className="col-imagen" style={{ backgroundImage: "url(/img/analisis-vulnerabilidades-2.jpg)" }}></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <CtaServicios />
        </Layout>
    )
}